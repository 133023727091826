import { Injectable } from '@angular/core';
import {Form} from '../models/form';
import {AngularFireDatabase} from '@angular/fire/database';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContactFormService {

  items: Observable<any[]>;

  constructor(private database: AngularFireDatabase) {
    this.items = database.list('forms').valueChanges();
  }

  sendForm(form: Form) {
    this.database.list('/forms').push(form);
  }
}
