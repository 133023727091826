import {Component, HostListener, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'hawk-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  headerImage1Path: string;
  isCollapsed: boolean;

  ngOnInit() {
  }

  constructor(private translate: TranslateService) {
    this.headerImage1Path = '/assets/images/header/LogoText.png';
    this.isCollapsed = true;
  }

  toggleMenu() {
    this.isCollapsed = !this.isCollapsed;

    if (this.isCollapsed) {
      document.getElementById('htn-button').setAttribute('aria-expanded', 'false');
      document.getElementById('htn-navbar').setAttribute('aria-expanded', 'false');
      document.getElementById('htn-button').setAttribute('class', 'navbar-toggler collapsed');
      document.getElementById('htn-navbar').setAttribute('class', 'navbar-collapse justify-content-center font-weight-bold collapse');
    }
  }

  openNav() {
    document.getElementById('mySidenav').style.width = '100%';
  }

  closeNav() {
    document.getElementById('mySidenav').style.width = '0';
  }

  switchLanguage(language: string) {
    this.translate.use(language);
  }
}
