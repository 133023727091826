import {Component, HostListener, OnInit} from '@angular/core';

@Component({
  selector: 'hawk-hawcode',
  templateUrl: './hawcode.component.html',
  styleUrls: ['./hawcode.component.scss']
})
export class HawcodeComponent implements OnInit {

  htnImage1Path: string;

  constructor() {
    this.htnImage1Path = '/assets/images/hawcode/logo_vinculum.png';
  }

  ngOnInit(): void {
  }

}
