import {Component, HostListener, OnInit, ViewChild} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'hawk-header-sidenav',
  templateUrl: './header-sidenav.component.html',
  styleUrls: ['./header-sidenav.component.scss']
})
export class HeaderSidenavComponent implements OnInit {

  headerImage1Path: string;

  @ViewChild('sidenav', { static: true }) public el: any;
  @HostListener('swiperight', ['$event']) public swipePrev(event: any) {
    this.el.show();
  }

  constructor(private translate: TranslateService) {
    this.headerImage1Path = '/assets/images/header/LogoText.png';
  }

  ngOnInit() {
  }

  switchLanguage(language: string) {
    this.translate.use(language);
  }
}
