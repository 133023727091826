import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'hawk-policy',
  templateUrl: './policy.component.html',
  styleUrls: ['./policy.component.scss']
})
export class PolicyComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  openNav() {
    document.getElementById('termsAndConditions').style.width = '100%';
  }

  closeNav() {
    document.getElementById('termsAndConditions').style.width = '0';
  }

}
