import {Component, HostListener, OnInit} from '@angular/core';

@Component({
  selector: 'hawk-htn',
  templateUrl: './htn.component.html',
  styleUrls: ['./htn.component.scss']
})
export class HtnComponent implements OnInit {

  type = 'screen';
  htnImage1Path: string;

  constructor() {
    this.htnImage1Path = '/assets/images/htn/htn-cover.jpg';
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.checkWindowSize();
  }

  ngOnInit(): void {
    this.checkWindowSize();
  }

  private checkWindowSize() {
    if (window.innerWidth / window.innerHeight < 1.5 && window.innerWidth / window.innerHeight > 1.1) {
      this.type = 'tablet';
    } else {
      if (window.innerHeight < window.innerWidth) {
        this.type = 'screen';
      } else {
        this.type = 'mobile';
      }
    }
  }
}
