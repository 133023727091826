import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'hawk-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  footerImage1Path: string;

  constructor() {
    this.footerImage1Path = '/assets/images/footer/DiputacionJaen.png';
  }

  ngOnInit() {
  }

}
