import { Component, OnInit } from '@angular/core';
import {IParams} from 'angular-particle/lib';

@Component({
  selector: 'hawk-experience',
  templateUrl: './experience.component.html',
  styleUrls: ['./experience.component.scss']
})
export class ExperienceComponent implements OnInit {

  experienceImage1Path: string;
  experienceImage2Path: string;
  experienceImage3Path: string;
  experienceImage4Path: string;
  experienceImage5Path: string;
  experienceImage6Path: string;
  experienceImage7Path: string;
  experienceImage8Path: string;

  particlesStyle: object = {};
  particlesParams: IParams;
  particlesWidth = 100;
  particlesHeight = 100;

  constructor() {
    this.experienceImage1Path = '/assets/images/experience/att-logo.png';
    this.experienceImage2Path = '/assets/images/experience/ibm-logo.png';
    this.experienceImage3Path = '/assets/images/experience/novatec-logo.png';
    this.experienceImage4Path = '/assets/images/experience/nazaries-logo.png';
    this.experienceImage5Path = '/assets/images/experience/logixs-logo.png';
    this.experienceImage6Path = '/assets/images/experience/hawcode-logo.png';
    this.experienceImage7Path = '/assets/images/experience/tbscg-logo.png';
    this.experienceImage8Path = '/assets/images/experience/liantis-logo.png';
  }

  ngOnInit() {

    this.particlesStyle = {
      'position': 'absolute',
      'width': '100%',
      'height': '100%',
      'z-index': 0,
      'top': 0,
      'left': 0,
      'right': 0,
      'bottom': 0,
      'background-color': 'transparent'
    };

    this.particlesParams = {
      particles: {
        number: {
          value: 50,
          density: {
            enable: true,
            value_area: 800
          }
        },
        color: {
          value: '#1d1624'
        },
        shape: {
          type: 'circle',
          stroke: {
            width: 4,
            color: '#1d1624'
          },
          polygon: {
            nb_sides: 5
          },
          image: {
            src: 'img/github.svg',
            width: 100,
            height: 100
          }
        },
        opacity: {
          value: 0.5,
          random: false,
          anim: {
            enable: false,
            speed: 1,
            opacity_min: 0.1,
            sync: false
          }
        },
        size: {
          value: 3,
          random: true,
          anim: {
            enable: false,
            speed: 40,
            size_min: 0.1,
            sync: false
          }
        },
        line_linked: {
          enable: true,
          distance: 150,
          color: '#1d1624',
          opacity: 0.4,
          width: 3,
          shadow: {
            enable: false,
            blur: 0,
            color: ''
          }
        },
        move: {
          enable: true,
          speed: 3,
          direction: 'none',
          random: false,
          straight: false,
          out_mode: 'out',
          bounce: false,
          attract: {
            enable: false,
            rotateX: 600,
            rotateY: 1200
          }
        },
        array: []
      },
      interactivity: {
        detect_on: 'canvas',
        events: {
          onhover: {
            enable: true,
            mode: 'grab'
          },
          onclick: {
            enable: true,
            mode: 'push'
          },
          resize: true
        },
        modes: {
          grab: {
            distance: 400,
            line_linked: {
              opacity: 1
            }
          },
          bubble: {
            distance: 400,
            size: 40,
            duration: 2,
            opacity: 8,
          },
          repulse: {
            distance: 200,
            duration: 0.4
          },
          push: {
            particles_nb: 4
          },
          remove: {
            particles_nb: 2
          }
        }
      },
      retina_detect: true
    };
  }

}
