import {BrowserModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AngularFullpageModule} from '@fullpage/angular-fullpage';
import {SkillsComponent} from './skills/skills.component';
import {HeaderComponent} from './header/header.component';
import {CoverComponent} from './cover/cover.component';
import {ParticlesModule} from 'angular-particle';
import {HtnComponent} from './htn/htn.component';
import {HawcodeComponent} from './hawcode/hawcode.component';
import {ExperienceComponent} from './experience/experience.component';
import {AboutMeComponent} from './about-me/about-me.component';
import {ContactFormComponent} from './contact-form/contact-form.component';
import {FooterComponent} from './footer/footer.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HeaderSidenavComponent} from './header-sidenav/header-sidenav.component';
import {AngularFireDatabaseModule} from '@angular/fire/database';
import {AngularFireModule} from '@angular/fire';
import {environment} from '../environments/environment';
import {PolicyComponent} from './policy/policy.component';
import {NgxSpinnerModule} from 'ngx-spinner';
import {MDBBootstrapModulesPro, MDBSpinningPreloader, ToastModule} from 'ng-uikit-pro-standard';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

declare var Hammer: any;

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export class MyHammerConfig extends HammerGestureConfig {
  overrides = {
    pan: { direction: Hammer.DIRECTION_All },
    swipe: { direction: Hammer.DIRECTION_VERTICAL },
  } as any;

  buildHammer(element: HTMLElement) {
    return new Hammer(element, {
      touchAction: 'auto',
      inputClass: Hammer.SUPPORT_POINTER_EVENTS ? Hammer.PointerEventInput : Hammer.TouchInput,
      recognizers: [
        [Hammer.Swipe, {
          direction: Hammer.DIRECTION_HORIZONTAL
        }]
      ]
    });
  }
}

@NgModule({
  declarations: [
    AppComponent,
    SkillsComponent,
    HeaderComponent,
    CoverComponent,
    HtnComponent,
    HawcodeComponent,
    ExperienceComponent,
    AboutMeComponent,
    ContactFormComponent,
    FooterComponent,
    HeaderSidenavComponent,
    PolicyComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFullpageModule,
    FormsModule,
    HttpClientModule,
    ParticlesModule,
    NgxSpinnerModule,
    AngularFireDatabaseModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    AngularFireModule.initializeApp(environment.firebase),
    ToastModule.forRoot(),
    MDBBootstrapModulesPro.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],
  providers: [
    MDBSpinningPreloader,
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
