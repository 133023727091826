import { Component, OnInit } from '@angular/core';
import {Form} from '../models/form';
import {ContactFormService} from './contact-form.service';
import {NgForm} from '@angular/forms';
import {ToastService} from 'ng-uikit-pro-standard';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'hawk-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent implements OnInit {

  form: Form;
  submitted = false;
  templateChecked = false;

  constructor(private contactFormService: ContactFormService, private toastService: ToastService, private translate: TranslateService) {
  }

  ngOnInit() {
    this.form = new Form();
  }

  public onSubmit(form: NgForm) {
    this.submitted = false;
    this.form.policyAccepted = this.templateChecked;
    this.contactFormService.sendForm(this.form);
    this.submitted = true;
    this.templateChecked = false;
    this.showSuccess();
    form.resetForm();
  }

  openNav() {
    document.getElementById('termsAndConditions').style.width = '100%';
  }

  closeNav() {
    document.getElementById('termsAndConditions').style.width = '0';
  }

  showSuccess() {
    this.translate.get('Contact_13').subscribe(value => {
      this.toastService.success(value);
      }
    );
  }
}
