import {Component, HostListener, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'hawk-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  config;
  fullPageApi;
  language: string;
  screenType = 'screen';

  ngOnInit(): void {
    this.spinner.show();
    this.checkWindowSize();
    this.checkCollipsed();
  }

  constructor(private translate: TranslateService, private spinner: NgxSpinnerService) {

    this.language = translate.getBrowserLang();
    translate.setDefaultLang(this.language);
    translate.use(this.language);

    this.config = {
      licenseKey: '91FD05AE-51D949EA-B148E903-5ABDEE38',
      anchors: ['aboutMePage', 'skillsPage', 'experiencePage', 'projectsPage', 'contactPage', 'footer'],
      menu: '#menu',
      normalScrollElements: '.scrollable-element',
      navigation: true,
      slidesNavigation: true,
      scrollOverflow: true,
      scrollOverflowOptions: {
        click: false
      },

      afterLoad: (origin, destination, direction) => {
        const index = destination.index;
        if (index === 2) {
          this.checkExperienceSection();
        }
        this.changeHeader(index);
      },
      afterRender: () => {
        this.spinner.hide();
      },
      afterResize: (width, height) => {
      },
      afterSlideLoad: (section, origin, destination, direction) => {
      }
    };
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.checkWindowSize();
    this.checkCollipsed();
  }

  getRef(fullPageRef) {
    this.fullPageApi = fullPageRef;
  }

  private checkWindowSize() {
    if (window.innerWidth / window.innerHeight < 1.5 && window.innerWidth / window.innerHeight > 1.1) {
      this.screenType = 'tablet';
    } else {
      if (window.innerHeight < window.innerWidth) {
        this.screenType = 'screen';
      } else {
        this.screenType = 'mobile';
      }
    }
  }

  private checkCollipsed() {
    if (window.innerWidth < 1000) {
      const standardHeader = document.getElementById('standard-header');
      standardHeader.classList.add('hidden-header');

      const sideHeader = document.getElementById('sidenav-header');
      sideHeader.classList.remove('hidden-header');
    } else {
      const standardHeader = document.getElementById('standard-header');
      standardHeader.classList.remove('hidden-header');

      const sideHeader = document.getElementById('sidenav-header');
      sideHeader.classList.add('hidden-header');
    }
  }

  private checkExperienceSection() {

    const experience1 = document.getElementById('experience1');
    experience1.classList.remove('hidden');
    experience1.classList.add('fadeInUp');

    const experience2 = document.getElementById('experience2');
    experience2.classList.remove('hidden');
    experience2.classList.add('fadeInUp');

    const experience3 = document.getElementById('experience3');
    experience3.classList.remove('hidden');
    experience3.classList.add('fadeInUp');

    const experience4 = document.getElementById('experience4');
    experience4.classList.remove('hidden');
    experience4.classList.add('fadeInUp');

    // const experience5 = document.getElementById('experience5');
    // experience5.classList.remove('hidden');
    // experience5.classList.add('fadeInUp');
    //
    // const experience6 = document.getElementById('experience6');
    // experience6.classList.remove('hidden');
    // experience6.classList.add('fadeInUp');
    //
    // const experience7 = document.getElementById('experience7');
    // experience7.classList.remove('hidden');
    // experience7.classList.add('fadeInUp');
    //
    // const experience8 = document.getElementById('experience8');
    // experience8.classList.remove('hidden');
    // experience8.classList.add('fadeInUp');
  }

  private checkArrows() {
    if (this.screenType === 'mobile') {
      const leftArrow = document.getElementsByClassName('fp-prev');
      if (leftArrow) {
        leftArrow[0].classList.remove('fp-controlArrow');
      }

      const rightArrow = document.getElementsByClassName('fp-next');
      if (rightArrow) {
        rightArrow[0].classList.remove('fp-controlArrow');
      }
    } else {
      const leftArrow = document.getElementsByClassName('fp-prev');
      if (leftArrow) {
        leftArrow[0].classList.add('fp-controlArrow');
      }

      const rightArrow = document.getElementsByClassName('fp-next');
      if (rightArrow) {
        rightArrow[0].classList.add('fp-controlArrow');
      }
    }
  }

  private changeHeader(index: number) {
    const header = document.getElementById('myHeader');

    if (index === 0) {
      header.classList.remove('header_nav--small');
      header.classList.add('header_nav--big');
    } else {
      header.classList.remove('header_nav--big');
      header.classList.add('header_nav--small');
    }
  }
}
